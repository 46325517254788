import { FeatureResolver } from '@modules/upgrade/pricing-table/pricing-table-features/feature-resolver';
import {
  adFeatureResolver,
  appsCountFeatureResolver,
  supportFeatureResolver,
  viewsLimitFeatureResolver,
  websitesLimitFeatureResolver,
  widgetsLimitFeatureResolver,
  additionalFeaturesFeatureResolver,
  installationServiceFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  advancedCustomizationFeatureResolver,
  aiPostGenerationFeatureResolver,
  aiChatbotFeatureResolver
} from './feature-resolvers';

export const enterpriseFeatureResolvers: FeatureResolver[] = [
  appsCountFeatureResolver,
  websitesLimitFeatureResolver,
  viewsLimitFeatureResolver,
  aiChatbotFeatureResolver,
  widgetsLimitFeatureResolver,
  projectsLimitFeatureResolver,
  collaboratorsLimitFeatureResolver,
  projectsSharingFeatureResolver,
  aiReviewsTranslationFeatureResolver,
  aiPostGenerationFeatureResolver,
  advancedCustomizationFeatureResolver,
  installationServiceFeatureResolver,
  supportFeatureResolver,
  adFeatureResolver,
  additionalFeaturesFeatureResolver
];
