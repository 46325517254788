import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Loader } from '@elfsight-universe/ui-common';
import { HeadController } from '@components';
import {
  NextPageWithConfig,
  useAppCurrentAppAliasContext,
  useUser
} from '@modules/_app';
import { DashboardStartPage } from '@modules/dashboard/dashboard-start-page';

const Apps: NextPageWithConfig = () => {
  const { replace } = useRouter();
  const { isAuthenticated } = useUser();
  const { currentAppAlias, isReady, myAppsNavLink, hasSharedWidgetPage } =
    useAppCurrentAppAliasContext();

  useEffect(() => {
    if (isAuthenticated && myAppsNavLink) {
      replace(myAppsNavLink);
    }
  }, [myAppsNavLink, isAuthenticated]);

  if (!currentAppAlias && !hasSharedWidgetPage && isReady) {
    return (
      <>
        <HeadController title="My Apps" />
        <DashboardStartPage />
      </>
    );
  }

  return (
    <>
      <HeadController title="My Apps" />
      <Loader absolute />
    </>
  );
};

Apps.config = {};

export default Apps;
