import styled from 'styled-components';
import {
  Countdown,
  getColorWithAlpha,
  Typography
} from '@elfsight-universe/ui-common';
import { useDealByAliasQuery } from '@api';
import { XMAS_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import backgroundImage from './assets/xmas-banner@2x.png';

export const XmasTopBar = () => {
  const { data } = useDealByAliasQuery({ alias: XMAS_DEAL_ALIAS });

  const dealExpiredAt =
    data?.accountDeal?.customData?.expiredAt ||
    data?.accountDeal?.expiredAt ||
    data?.accountDeal?.deal.expiredAt;

  return (
    <Container>
      <Content>
        <Typography color="white" variant="title4Semibold">
          CHRISTMAS SPECIAL
        </Typography>
        {dealExpiredAt && (
          <>
            <Divider />
            <Countdown
              font="title4Semibold"
              iconSize={14}
              expiredAt={dealExpiredAt}
              color="white"
              fallback="33% OFF - LIMITED TIME DEAL"
            />
          </>
        )}
      </Content>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px;
  ${({ theme }) => theme.font.text}
`;

const Divider = styled.div`
  height: 10px;
  width: 1px;
  background-color: ${({ theme }) =>
    getColorWithAlpha(theme.colors['white'], 0.5)};
  border-radius: 1px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.xmasDeepRed};
  background-image: url(${backgroundImage.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 26px;
`;
