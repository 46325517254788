import { ParsedUrlQueryInput } from 'querystring';
import { useRouter } from 'next/router';

type QueryParamValuesType<D extends string> = {
  [key in D]: string | undefined;
};

export function useReplaceMultipleQueryParams<T extends string>(
  queryParamNames: T[],
  scrollToTop = false
): [
  Partial<QueryParamValuesType<T>>,
  (nextQueryParamValues: Partial<QueryParamValuesType<T>>) => Promise<boolean>
] {
  const { query, pathname, replace } = useRouter();

  const queryParamValues: Partial<QueryParamValuesType<T>> = {};
  queryParamNames.forEach((paramName) => {
    if (query[paramName]) {
      queryParamValues[paramName] = query[paramName] as string;
    }
  });

  const replaceQueryParams = async (
    nextQueryParamValues: Partial<QueryParamValuesType<T>>
  ) => {
    const newQuery: ParsedUrlQueryInput = { ...query };

    Object.entries(nextQueryParamValues).forEach(([queryKey, value]) => {
      if ([null, undefined, ''].some((check) => check === value)) {
        delete newQuery[queryKey];
      } else {
        newQuery[queryKey] = value as string;
      }
    });

    return replace({ pathname, query: newQuery }, undefined, {
      scroll: scrollToTop
    });
  };

  return [queryParamValues, replaceQueryParams];
}
