import styled from 'styled-components';
import { Masonry } from 'masonic';
import { ElfsightGlyph, Check16 } from '@elfsight/icons';
import {
  H1,
  H3,
  Icon,
  Loader,
  MOBILE,
  ModalWithScrollableBody,
  ModalWithScrollableBodyProps,
  Typography
} from '@elfsight-universe/ui-common';
import { usePackAppsListWithCategoriesQuery } from '@api';

const PRETTY_CATEGORIES_ORDER_ALIASES = [
  'social',
  'reviews',
  'chats',
  'tools',
  'forms',
  'video',
  'e-commerce',
  'audio'
];

type UpgradeAllAppsPackModalProps = ModalWithScrollableBodyProps;

export function UpgradeAllAppsPackModal({
  isOpen,
  ...forwardingProps
}: UpgradeAllAppsPackModalProps) {
  const { data: appsWithCategories = { payload: [] }, isLoading } =
    usePackAppsListWithCategoriesQuery();

  const prettifiedOrderApps = appsWithCategories.payload
    .slice()
    .sort(
      (a, b) =>
        PRETTY_CATEGORIES_ORDER_ALIASES.indexOf(a.category.alias) -
        PRETTY_CATEGORIES_ORDER_ALIASES.indexOf(b.category.alias)
    );

  return (
    <ModalWithScrollableBody
      maxWidth={1000}
      padding={[0, 0]}
      isOpen={isOpen}
      withClose
      headerOffsetBottom={24}
      header={
        <Title>
          <ElfsightLogoWrapper>
            <Icon component={ElfsightGlyph} size={18} fill="white" />
          </ElfsightLogoWrapper>
          <Caption>All Apps Pack</Caption>
        </Title>
      }
      {...forwardingProps}
    >
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Content>
            <Masonry
              overscanBy={10}
              items={prettifiedOrderApps}
              columnGutter={24}
              rowGutter={32}
              columnWidth={120}
              maxColumnCount={4}
              render={({ data: { apps, category } }) => (
                <AppsColumn key={category.alias}>
                  <CategoryCaption>{category.name}</CategoryCaption>

                  <ListColumn>
                    {apps.map((app) => (
                      <ListColumnItem key={app.alias}>
                        <CheckIcon
                          size={16}
                          component={Check16}
                          fill="success"
                        />
                        {app.name}
                      </ListColumnItem>
                    ))}
                  </ListColumn>
                </AppsColumn>
              )}
            />

            <CenteredText variant="text" alignCenter>
              + all upcoming apps
            </CenteredText>
          </Content>
        )}
      </Container>
    </ModalWithScrollableBody>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 40px 40px 0;

  ${MOBILE} {
    padding: 20px 20px 0;
  }
`;

const ElfsightLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #fa527a 0%, #f80741 172.5%);
  border-radius: 12px;
  width: 32px;
  height: 32px;
`;

const Caption = styled(H1)`
  ${MOBILE} {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  padding: 0 40px 40px;
  max-height: 100%;

  ${MOBILE} {
    padding: 0 20px 20px;
  }
`;

const AppsColumn = styled.div``;

const CategoryCaption = styled(H3)`
  margin-bottom: 12px;
`;

const ListColumn = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListColumnItem = styled.li`
  list-style: none;
  display: flex;
  align-items: flex-start;

  &:not(:first-child) {
    margin-top: 8px;
  }
`;

const CenteredText = styled(Typography)`
  ${({ theme }) => theme.font.title3Semibold};
`;

const CheckIcon = styled(Icon)`
  margin-right: 8px;
`;
