import { formatSeparatorNumber } from '@elfsight-universe/ui-common';
import { CreateFeatureOptions, FeatureResolver } from '../feature-resolver';
import { FeatureTooltip } from '../../components/feature-tooltip';

const AI_CHATBOT_APP_ALIAS = 'ai-chatbot';

export const aiChatbotFeatureResolver: FeatureResolver = ({
  isPackPlan,
  appAlias,
  planPolicyFeatures
}: CreateFeatureOptions) => {
  const shouldDisplay =
    !isPackPlan &&
    appAlias === AI_CHATBOT_APP_ALIAS &&
    planPolicyFeatures?.messagesLimit;

  if (!shouldDisplay) {
    return;
  }

  return {
    text: (
      <FeatureTooltip
        content={
          <>
            The maximum number of messages your visitors can send to the chatbot
            per month.
          </>
        }
      >
        <>
          {planPolicyFeatures?.messagesLimit &&
            formatSeparatorNumber(planPolicyFeatures.messagesLimit)}{' '}
          messages
        </>
      </FeatureTooltip>
    )
  };
};
