import { ReactNode } from 'react';
import BaseCountdown from 'react-countdown';
import styled from 'styled-components';
import { Sandclock16 } from '@elfsight/icons';
import { FontType, ThemeColor } from '../theme';
import { Icon } from './icons';

export type CountdownProps = {
  expiredAt: Date | string;
  color?: ThemeColor;
  timerMinWidth?: number;
  font?: FontType;
  iconSize?: number;
  fallback?: ReactNode;
};

export function Countdown({
  expiredAt,
  color = 'black',
  timerMinWidth = 74,
  font = 'title4',
  iconSize = 12,
  fallback
}: CountdownProps) {
  const fallbackComponent = fallback ?? (
    <>
      <StyledIcon component={Sandclock16} size={iconSize} />
      <span>Limited Time Deal</span>
    </>
  );

  return (
    <BaseCountdown
      date={expiredAt}
      renderer={({ hours, minutes, seconds, completed }) =>
        completed ? (
          <FallbackContainer _font={font} _color={color}>
            {fallbackComponent}
          </FallbackContainer>
        ) : (
          <Container _color={color}>
            <StyledIcon component={Sandclock16} size={iconSize} />
            <Timer
              role="timer"
              style={{ minWidth: timerMinWidth }}
              _font={font}
            >
              {to2Digit(hours)}:{to2Digit(minutes)}:{to2Digit(seconds)}
            </Timer>
          </Container>
        )
      }
    />
  );
}

const Container = styled.div<{ _color: ThemeColor }>`
  display: flex;
  align-items: center;
  color: ${({ _color, theme }) => theme.colors[_color]};
`;

const FallbackContainer = styled.div<{ _font: FontType; _color: ThemeColor }>`
  display: flex;
  align-items: center;
  color: ${({ _color, theme }) => theme.colors[_color]};

  ${({ theme, _font }) => theme.font[_font]};
`;

const StyledIcon = styled(Icon)`
  margin-right: 4px;
`;

const Timer = styled.span<{ _font: FontType }>`
  ${({ theme, _font }) => theme.font[_font]}
`;

const to2Digit = (number: number) =>
  `${number}`.length === 1 ? `0${number}` : number;
