import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_FEATURED_OFFER_PATH,
  GetFeaturedOfferResponse,
  OfferType
} from '@elfsight-universe/service-core-contracts/deals';
import { useUser } from '@modules/_app';
import { useEvents } from '@modules/_event-bus';
import { SECOND_APP_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import { client } from '../client';

export function useFeaturedOfferQuery(
  options: UseQueryOptions<GetFeaturedOfferResponse> = {},
  onNewSecondAppOffer?: () => void
) {
  const { isAuthenticated } = useUser();

  const query = useQuery<GetFeaturedOfferResponse>(
    [GET_FEATURED_OFFER_PATH],
    () => client.get(GET_FEATURED_OFFER_PATH).json<GetFeaturedOfferResponse>(),
    {
      enabled: isAuthenticated,
      ...options
    }
  );

  useEvents(
    [
      'DealsUpdated',
      'SecondAppDealRestarted',
      'BirthdayDealUpdated',
      'DealWithInstantExtensionRestarted'
    ],
    async (event) => {
      const { data } = await query.refetch();

      const shouldHandleSecondAppOffer =
        event.type === 'DealsUpdated' &&
        onNewSecondAppOffer &&
        event.payload.dealAlias === SECOND_APP_DEAL_ALIAS &&
        data?.type === OfferType.SECOND_APP_COUPON;

      if (shouldHandleSecondAppOffer) {
        onNewSecondAppOffer();
      }
    }
  );

  return query;
}
