import { Exclude, Expose, Type } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';
import { BillingInterval, PlanGrade, PlanType } from './enums';
import { PlanPolicy, PlanPolicyFeatures } from './classes';
import { getTransformClassForPlanProps } from './utils';

export const GET_PACK_PLANS_PATH = 'billing/get-pack-plans';

export class GetPackPlansRequest {
  @IsString()
  @IsOptional()
  appAlias?: string;
}

@Exclude()
export class GetPackPlansResponse {
  @Expose()
  @Type(() => GetPackPlansResponsePlan)
  payload: GetPackPlansResponsePlan[];
}

@Exclude()
export class GetPackPlansResponsePlan {
  @Expose()
  pid: string;

  @Expose()
  name: string;

  @Expose()
  type: PlanType;

  @Expose()
  @Type(getTransformClassForPlanProps)
  properties: {
    grade: PlanGrade;
    billingInterval: BillingInterval;
    price: number;
    icon?: string;
    paddleId: number;
  };

  @Expose()
  @Type(() => PlanPolicy)
  policy: {
    viewsLimit: number | null;
    widgetsLimit: number;
    projectsLimit: number;
    collaboratorsLimit: number;
    features?: PlanPolicyFeatures;
  };

  @Expose()
  appsCount: number;

  @Expose()
  caption?: string;

  @Expose()
  highlight?: boolean;

  @Expose()
  pricePerMonth: number;

  @Expose()
  pricePerMonthDiscounted?: number;

  @Expose()
  pricePerWidget: number;

  @Expose()
  discountTotal?: number;

  @Expose()
  discountPercentage?: number;

  @Expose()
  isEnterprisePlan: boolean;
}
