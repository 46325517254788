import styled from 'styled-components';
import {
  Countdown,
  getColorWithAlpha,
  Typography
} from '@elfsight-universe/ui-common';
import { useDealByAliasQuery } from '@api';
import { BLACK_FRIDAY_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';

export const BlackFridayTopBar = () => {
  const { data } = useDealByAliasQuery({ alias: BLACK_FRIDAY_DEAL_ALIAS });

  const dealExpiredAt =
    data?.accountDeal?.customData?.expiredAt ||
    data?.accountDeal?.expiredAt ||
    data?.accountDeal?.deal.expiredAt;

  return (
    <Container>
      <Content>
        <Typography color="white" variant="title4Semibold">
          BLACK FRIDAY SALE
        </Typography>
        {dealExpiredAt && (
          <>
            <Divider />
            <Countdown
              font="title4Semibold"
              iconSize={14}
              expiredAt={dealExpiredAt}
              color="white"
              fallback="50% OFF - LIMITED TIME DEAL"
            />
          </>
        )}
      </Content>
    </Container>
  );
};

const Content = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 4px;
  ${({ theme }) => theme.font.text}
`;

const Divider = styled.div`
  height: 10px;
  width: 1px;
  background-color: ${({ theme }) =>
    getColorWithAlpha(theme.colors['white'], 0.5)};
  border-radius: 1px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #111;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTM5IiBoZWlnaHQ9IjI2IiB2aWV3Qm94PSIwIDAgOTM5IDI2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNODE2LjMwMyAtMTVIODE2LjM5Nkw4MDUuNTE5IDM1LjUwOThIOTM5VjQxSDY2OC4wMDJMNjczLjgyMiAtOS41MDk3N0g2NjYuOTUyTDY2MS4zMDcgNDFINjUzLjM1M0w2NjEuMzA3IC05LjUwOTc3SDY1OS41ODlMNjUxLjY4IDQxSDYzOC4wNzZMNjQ3LjgyOSAtOS41MDk3N0g2NDYuOTdMNjM3LjIzOSA0MUgwVjM4LjVINDYuNTY5TDYzLjM4MzEgLTcuNDk5OTZIMFYtMTVMNjc2LjEzNiAtMTVMNjcxLjg5NiAzNS41MDk4SDY4Mi43NzZMNjg3LjMgLTE1SDY5MS45NzdMNjg3LjMgMzUuNTA5OEg3MjQuOTZMNzMwLjYyMiAtMTVINzM2LjQzNkw3MjYuNzcxIDM1LjUwOThIODA0LjYwM0w4MTYuMzAzIC0xNVpNNjQuNjU1NiAtNy40OTk5NkgxNzguNzUyTDE2NC41ODMgMzguNUg0Ny41MDM5TDY0LjY1NTYgLTcuNDk5OTZaTTIzNi42MTQgLTcuNDk5OTZIMTgxLjQwNkwxNzMuMTA3IDM4LjVIMjI5Ljc1OEwyMzYuNjE0IC03LjQ5OTk2Wk0yNDMuMjQ2IC03LjQ5OTk2SDI1OS4xOTVMMjUyLjk4IDM4LjVIMjM2LjYxNEwyNDMuMjQ2IC03LjQ5OTk2Wk0yNzQuNzE5IC0xMi41SDI2NC45MDRMMjU2LjM3MiAzMy41SDI2Ni40NDNMMjc0LjcxOSAtMTIuNVpNMjg2LjM3NyAtMTIuNUgyODguODMxTDI3Ny4yMzYgMzMuNUgyNzQuNzE4TDI4Ni4zNzcgLTEyLjVaTTMxMCAtMTIuNUgzMDguNzczTDI5NC40NzYgMzMuNUgyOTUuNzM1TDMxMCAtMTIuNVoiIGZpbGw9IiNFMDJENTgiLz4KPC9zdmc+Cg==');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 939px 26px;
`;
