import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState
} from 'react';
import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { useDealByAliasQuery } from '@api';
import {
  BIRTHDAY_DEAL_ALIAS,
  BLACK_FRIDAY_DEAL_ALIAS,
  XMAS_DEAL_ALIAS
} from '@modules/deals/utils/deal-aliases';
import { BirthdayModal } from '@modules/instant-extension-campaign/birthday/birthday-modal/birthday-modal';
import { XmasModal } from '@modules/instant-extension-campaign/xmas/xmas-modal';
import { BlackFridayModal } from '@modules/instant-extension-campaign/black-friday/black-friday-modal/black-friday-modal';

interface IInstantExtensionCampaignContext {
  dealExpiredAt?: Date;
  accountDeal?: AccountDeal | null;
  alias?: string;
  isReady: boolean;
}
export const InstantExtensionCampaignContext =
  createContext<IInstantExtensionCampaignContext | null>(null);

export function InstantExtensionCampaignProvider({
  children
}: PropsWithChildren) {
  // change to active campaign deal (BIRTHDAY_DEAL_ALIAS/BLACK_FRIDAY_DEAL_ALIAS/XMAS_DEAL_ALIAS)
  const { data } = useDealByAliasQuery({ alias: XMAS_DEAL_ALIAS });
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    setReady(true);
  }, [data]);

  const currentAlias = data?.accountDeal?.deal.alias;
  const dealExpiredAt =
    data?.accountDeal?.customData?.expiredAt ||
    data?.accountDeal?.expiredAt ||
    data?.accountDeal?.deal.expiredAt;

  return (
    <InstantExtensionCampaignContext.Provider
      value={{
        accountDeal: data?.accountDeal,
        alias: data?.accountDeal?.deal.alias,
        dealExpiredAt,
        isReady
      }}
    >
      {children}

      {
        {
          [BIRTHDAY_DEAL_ALIAS]: <BirthdayModal />,
          [BLACK_FRIDAY_DEAL_ALIAS]: <BlackFridayModal />,
          [XMAS_DEAL_ALIAS]: <XmasModal />,
          default: null
        }[currentAlias || 'default']
      }
    </InstantExtensionCampaignContext.Provider>
  );
}

export function useInstantExtensionCampaignContext() {
  const context = useContext(InstantExtensionCampaignContext);

  if (context === null) {
    throw new Error(
      '`useInstantExtensionCampaignProvider` must be nested inside an `InstantExtensionCampaignProvider`.'
    );
  }

  return context;
}
