import Router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import subMonths from 'date-fns/subMonths';
import {
  Button,
  getColorWithAlpha,
  H1,
  P,
  useTheme,
  DisposableModal,
  DisposableModalAPI,
  MOBILE
} from '@elfsight-universe/ui-common';
import { useDealByAliasQuery } from '@api';
import { usePageContext, useUser } from '@modules/_app';
import { XMAS_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import { WIDGET_INSTALL_PID_QUERY_PARAM } from '@modules/widget-install';
import { XmasHeading } from '../xmas-sale-page/components/xmas-heading';
import forestBG from './assets/forest.svg?url';
import snowBG from './assets/snow.svg?url';

const disposableInstanceYear = subMonths(new Date(), 6).getFullYear();
const DISPOSABLE_INSTANCE_ID = `christmas-sale-${disposableInstanceYear}`;

export function XmasModal() {
  const { user } = useUser();
  const { offerModalHidden } = usePageContext();
  const disposableModalAPIRef = useRef<DisposableModalAPI | null>(null);
  const theme = useTheme();
  const { query } = useRouter();
  const { data: xmasDeal } = useDealByAliasQuery(
    { alias: XMAS_DEAL_ALIAS },
    {
      enabled: !offerModalHidden
    }
  );

  const installParamValue = query[WIDGET_INSTALL_PID_QUERY_PARAM] as
    | string
    | undefined;

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(
      !!xmasDeal?.accountDeal && !offerModalHidden && !installParamValue
    );
  }, [xmasDeal?.accountDeal, offerModalHidden, installParamValue]);

  const handleAction = () => {
    Router.push('/christmas-sale');
    disposableModalAPIRef.current?.requestClose();
  };

  return (
    <DisposableModal
      maxWidth={460}
      closeColor={getColorWithAlpha(theme.colors.white, 0.5)}
      active={active}
      instanceId={DISPOSABLE_INSTANCE_ID}
      accountPid={user?.accountPid}
      containerComponent={StyledContainer}
      ref={disposableModalAPIRef}
    >
      <XmasHeading withShadow />

      <Content>
        <ContentScreamSale>33% OFF</ContentScreamSale>
        <ContentDescription>Catch the end-of-year sale!</ContentDescription>
      </Content>

      <CenteredButton
        type="submit"
        onClick={handleAction}
        backgroundColor={theme.colors.black}
        padding={20}
        fontWeight={600}
        large
        center
      >
        Grab Deal
      </CenteredButton>
    </DisposableModal>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  background-color: ${({ theme }) => theme.colors.xmasDeepRed};
  background-image: url(${snowBG.src}), url(${forestBG.src});
  background-position: top, bottom;
  background-repeat: no-repeat;
  border-radius: 8px;
  overflow: hidden;

  ${MOBILE} {
    padding: 60px 20px;
  }
`;

const Content = styled.div`
  margin: 10px 0 40px;
  text-align: center;
`;

const ContentScreamSale = styled(H1)`
  font-size: 60px;
  font-weight: 900;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
`;
const ContentDescription = styled(P)`
  ${({ theme }) => theme.font.title2};
  color: ${({ theme }) => theme.colors.white};
`;

const CenteredButton = styled(Button)`
  margin: 0 auto;
  width: 200px;
`;
